import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/layouts/post-layout.js"
import CustomImage from '$components/CustomImage'
import Carousel from '$components/Carousel'
import DataProvider from '$components/DataProvider'
import { graphql } from 'gatsby'
export const pageQuery = graphql`
    query GatsbyImagePhotoeditor {
        ...ImagesPhotoeditor
    }
`
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>



<MDXTag name="h1" components={components}>{`Introduction`}</MDXTag>
<MDXTag name="p" components={components}>{`PhotoEditorSDK is a family of products that include Web, Android and an iOS application.
For all those platforms the integrations range from Amazons AWS photo storage to HPs sprocket printer.
In 2016 I joined PhotoEditorSDK (9elements, img.ly) to prepare the a concept for the challenges ahead.`}</MDXTag>
<MDXTag name="h1" components={components}>{`Outcome`}</MDXTag>
<MDXTag name="h2" components={components}>{`Advanced Mobile Version`}</MDXTag>
<Carousel wrapper>
    <CustomImage imgSrc="home" description="Home" />
    <CustomImage imgSrc="transform" description="Transform" />
    <CustomImage imgSrc="filter" description="Filter" />
    <CustomImage imgSrc="text_font" description="Text Font Selection" />
    <CustomImage imgSrc="overlay" description="Overlays" />
    <CustomImage imgSrc="focus" description="Focus" />
    <CustomImage imgSrc="brush_dragging" description="Brush Hardness" />
    <CustomImage imgSrc="sticker_color" description="Color Selection" />
</Carousel>
<MDXTag name="h2" components={components}>{`Challenges`}</MDXTag>
<MDXTag name="p" components={components}>{`[...]`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  